import { useState } from "react";
import { Collapse } from "react-collapse";
import Typography from "@mui/joy/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function Dropdown({ title, content, isText }) {
	const [isContentVisible, setIsContentVisible] = useState(false);

	return (
		<div style={{ margin: "0.25rem 0" }}>
			<div
				onClick={() => setIsContentVisible(!isContentVisible)}
				style={{
					cursor: "pointer",
					width: "120px",
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					userSelect: "none"
				}}
			>
				<Typography level="body-md" fontSize={12}>
					{title}
				</Typography>
				{isContentVisible ? (
					<KeyboardArrowUpIcon sx={{ fontSize: "16px" }} />
				) : (
					<KeyboardArrowDownIcon sx={{ fontSize: "16px" }} />
				)}
			</div>

			<Collapse isOpened={isContentVisible}>
				<div className="content-container" style={{ paddingTop: isText ? "0.25rem" : "0.5rem" }}>
					{isText ? (
						<Typography level="body-sm" fontSize={12}>
							{content}
						</Typography>
					) : (
						content
					)}
				</div>
			</Collapse>
		</div>
	);
}
