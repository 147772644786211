import {
	Typography,
	Stack,
	Card,
	IconButton,
	Button,
	Textarea,
	Dropdown,
	Menu,
	MenuItem,
	MenuButton,
	ListItemDecorator,
	Modal,
	ModalClose,
	Sheet,
	List,
	ListItem,
	Checkbox,
	Avatar,
	AvatarGroup
} from "@mui/joy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import axios from "axios";
import { useEffect, useState } from "react";
import { getToken } from "../../lib/auth";
import { useNavigate } from "react-router-dom";
import { differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";
import AiSuggestionButton from "./AiSuggestionButton";

function formatUpdatedAt(updatedAt) {
	const now = new Date();
	const updatedDate = new Date(updatedAt);

	const diffInMinutes = differenceInMinutes(now, updatedDate);
	const diffInHours = differenceInHours(now, updatedDate);
	const diffInDays = differenceInDays(now, updatedDate);

	if (diffInMinutes < 1) {
		return "just now";
	} else if (diffInMinutes === 1) {
		return "1 minute ago";
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes} minutes ago`;
	} else if (diffInHours < 24) {
		return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
	} else {
		return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
	}
}

export default function Lists({ project, lists, setLists, contributors }) {
	const navigate = useNavigate();

	const [openedMenuId, setOpenedMenuId] = useState(null);
	const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
	const [currentItemId, setCurrentItemId] = useState(null);
	const [currentListId, setCurrentListId] = useState(null);

	const [featuresItems, setFeaturesItems] = useState("");

	const [moveModalOpen, setMoveModalOpen] = useState(false);
	const [currentItem, setCurrentItem] = useState(null);

	const openMoveModal = (projectId, listId, itemId) => {
		setCurrentItem({ projectId, listId, itemId });
		setMoveModalOpen(true);
	};

	const closeMoveModal = () => {
		setCurrentItem(null);
		setMoveModalOpen(false);
	};

	const moveItemToList = async targetListId => {
		if (!currentItem) return;

		const token = getToken("access");
		if (!token) {
			navigate("/login");
			return;
		}

		const originalList = lists.find(list => list.id === currentItem.listId);
		const itemToMove = originalList.items.find(item => item.id === currentItem.itemId);

		try {
			await axios.delete(
				`/api/projects/${currentItem.projectId}/lists/${currentItem.listId}/list-items/${currentItem.itemId}/`,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const response = await axios.post(
				`/api/projects/${currentItem.projectId}/lists/${targetListId}/list-items/`,
				itemToMove,
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const updatedLists = [...lists];
			originalList.items = originalList.items.filter(item => item.id !== currentItem.itemId);
			const targetList = updatedLists.find(list => list.id === targetListId);
			targetList.items.push(response.data);

			setLists(updatedLists);
			closeMoveModal();
		} catch (error) {
			console.error("Error moving the item:", error);
		}
	};

	useEffect(() => {
		const featuresList = lists.find(list => list.name === "Features");
		console.log(featuresList);
		const featuresListItems = featuresList?.items.map(item => item.content);
		console.log(featuresListItems);
		const featuresListItemsString = featuresListItems?.join(", ");
		setFeaturesItems(featuresListItemsString);
	}, [lists, featuresItems]);

	const handleAddItem = async (listId, content = "new-list-item") => {
		const token = getToken("access");
		if (!token) {
			navigate("/login");
			return;
		}

		try {
			const response = await axios.post(
				`/api/projects/${project.id}/lists/${listId}/list-items/`,
				{
					content: content
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const newItem = response.data;

			const updatedLists = [...lists];
			const listIndex = updatedLists.findIndex(list => list.id === listId);
			updatedLists[listIndex].items.push(newItem);
			setLists(updatedLists);

			if (content === "new-list-item") {
				setTimeout(() => {
					const textareaElement = document.querySelector(`.textarea-${listId}-${newItem.id} textarea`);
					if (textareaElement) {
						textareaElement.focus();
					}
				}, 0);
			}
		} catch (error) {
			console.error("Error creating a new list item:", error);
		}
	};

	const handleItemUpdate = async (listId, itemId, value) => {
		try {
			const token = getToken("access");
			if (!token) {
				navigate("/login");
			}

			if (value === "") value = "new-list-item";

			await axios.patch(
				`/api/projects/${project.id}/lists/${listId}/list-items/${itemId}/`,
				{
					content: value
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const updatedLists = [...lists];
			const listIndex = updatedLists.findIndex(list => list.id === listId);
			const itemIndex = updatedLists[listIndex].items.findIndex(item => item.id === itemId);
			updatedLists[listIndex].items[itemIndex].content = value;
			setLists(updatedLists);
		} catch (error) {
			console.error("Error updating list item:", error);
		}
	};

	const handleToggleMenu = itemId => {
		if (openedMenuId === itemId) {
			setOpenedMenuId(null);
		} else {
			setOpenedMenuId(itemId);
		}
	};

	const handleDeleteItem = async (listId, itemId) => {
		setOpenedMenuId(null);
		try {
			const token = getToken("access");
			if (!token) {
				navigate("/login");
			}

			await axios.delete(`/api/projects/${project.id}/lists/${listId}/list-items/${itemId}/`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			const updatedLists = [...lists];
			const listIndex = updatedLists.findIndex(list => list.id === listId);
			const itemIndex = updatedLists[listIndex].items.findIndex(item => item.id === itemId);
			updatedLists[listIndex].items.splice(itemIndex, 1);
			setLists(updatedLists);
		} catch (error) {
			console.error("Error updating list item:", error);
		}
	};

	const openAssignModal = (listId, itemId) => {
		setCurrentListId(listId);
		setCurrentItemId(itemId);
		setIsAssignModalOpen(true);
	};

	const closeAssignModal = () => {
		setCurrentItemId(null);
		setIsAssignModalOpen(false);
	};

	const handleCheckboxChange = async (contributor, isChecked) => {
		const token = getToken("access");
		if (!token) {
			navigate("/login");
			return;
		}

		try {
			await axios.post(
				`/api/projects/${project.id}/lists/${currentListId}/list-items/${currentItemId}/assign-user/`,
				{
					username: contributor.username
				},
				{
					headers: {
						Authorization: `Bearer ${token}`
					}
				}
			);

			const updatedLists = [...lists];
			const listIndex = updatedLists.findIndex(list => list.id === currentListId);
			const itemIndex = updatedLists[listIndex].items.findIndex(item => item.id === currentItemId);
			const currentItem = updatedLists[listIndex].items[itemIndex];

			if (isChecked) {
				const isUserAlreadyAssigned = currentItem.assigned_users.some(
					user => user.username === contributor.username
				);
				if (!isUserAlreadyAssigned) {
					currentItem.assigned_users.push(contributor);
				}
			} else {
				currentItem.assigned_users = currentItem.assigned_users.filter(
					user => user.username !== contributor.username
				);
			}

			setLists(updatedLists);
		} catch (error) {
			console.error("Error assigning/unassigning user:", error);
		}
	};

	const addAiSuggestion = message => {
		const featuresListId = lists.find(list => list.name === "Features")?.id;

		if (featuresListId) {
			handleAddItem(featuresListId, message);
		}
	};

	return (
		<Stack
			direction="row"
			spacing="1rem"
			className="lists-container"
			sx={{ width: "100%", overflowX: "auto", mt: 1.5, flexGrow: 1, position: "relative" }}
		>
			{lists.length > 0
				? lists.map(list => (
						<Card
							key={list.id}
							sx={{ borderColor: "rgba(0, 0, 0, 0.1)", borderBottom: "none" }}
							variant="outlined"
							className="list-card"
						>
							<Stack className="list-container" spacing={1.35} sx={{ overflowY: "auto" }}>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="flex-start"
									sx={{ height: "32px", minHeight: "32px" }}
								>
									<Typography
										level="body-md"
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap",
											maxWidth: "calc(100% - 2rem)",
											fontWeight: "600",
											cursor: "default"
										}}
									>
										{list.name}
									</Typography>
									{list.name === "Features" && (
										<AiSuggestionButton
											description={project.description}
											name={project.name}
											addAiSuggestion={addAiSuggestion}
											featuresItems={featuresItems}
										/>
									)}
								</Stack>
								<Button
									className="list-item"
									variant="soft"
									color="neutral"
									sx={{ width: "100%", minHeight: "fit-content" }}
									onClick={() => handleAddItem(list.id)}
								>
									<AddIcon />
								</Button>

								{[...list.items].reverse().map(item => {
									if (item.content && item.list === list.id) {
										// console.log(contributors);
										// console.log(item.assigned_users);
										const assignedContributors = item.assigned_users
											.map(user =>
												contributors.find(contributor => contributor.username === user.username)
											)
											.filter(Boolean);
										// console.log(assignedContributors);

										return (
											<Card
												key={item.id}
												className="list-item"
												variant="soft"
												color="neutral"
												sx={{ gap: "1rem" }}
											>
												<Stack
													direction="row"
													justifyContent="space-between"
													alignItems="center"
													sx={{ position: "relative" }}
												>
													<Typography
														level="body-sm"
														fontSize={10}
														sx={{ cursor: "default" }}
													>
														Created {formatUpdatedAt(item.created_at)}
													</Typography>

													<Dropdown onOpenChange={() => handleToggleMenu(item.id)}>
														<MenuButton
															slots={{ root: IconButton }}
															color="neutral"
															variant="plain"
															sx={{
																position: "absolute",
																right: "0",
																top: "-7px",
																"&:active": { transform: "translateY(1px)" }
															}}
														>
															<MoreHorizIcon />
														</MenuButton>
														<Menu color="neutral" variant="soft" placement="bottom-end">
															<MenuItem
																color="neutral"
																onClick={() =>
																	openMoveModal(project.id, list.id, item.id)
																}
															>
																<ListItemDecorator sx={{ marginRight: "-1rem" }}>
																	<CompareArrowsIcon />
																</ListItemDecorator>
																Move
															</MenuItem>

															<MenuItem
																disabled={contributors?.length > 0 ? false : true}
																color="neutral"
																onClick={() => openAssignModal(list.id, item.id)}
															>
																<ListItemDecorator sx={{ marginRight: "-1rem" }}>
																	<PersonAddIcon />
																</ListItemDecorator>
																Assign
															</MenuItem>

															<MenuItem
																color="danger"
																onClick={() => handleDeleteItem(list.id, item.id)}
															>
																<ListItemDecorator sx={{ marginRight: "-1rem" }}>
																	<DeleteForeverIcon />
																</ListItemDecorator>
																Delete
															</MenuItem>
														</Menu>
													</Dropdown>
												</Stack>

												<Textarea
													className={`unstyled-input list-item-input textarea-${list.id}-${item.id}`}
													name="description"
													variant="plain"
													color="neutral"
													placeholder={list.name === "Features" ? "New feature" : "New task"}
													size="md"
													defaultValue={item.content === "new-list-item" ? "" : item.content}
													onChange={e => handleItemUpdate(list.id, item.id, e.target.value)}
												/>

												<AvatarGroup>
													{assignedContributors?.slice(0, 5).map(contributor => (
														<Avatar
															key={contributor.id}
															src={contributor?.photo || ""}
															size="md"
															color="primary"
															variant="solid"
														>
															{(!contributor.photo &&
																contributor.display_name[0]?.toUpperCase()) ||
																contributor.username[0]?.toUpperCase()}
														</Avatar>
													))}
													{assignedContributors.length > 5 && (
														<Avatar>+{assignedContributors.length - 5}</Avatar>
													)}
												</AvatarGroup>
											</Card>
										);
									} else {
										return false;
									}
								})}
							</Stack>
						</Card>
				  ))
				: null}
			<Modal
				open={isAssignModalOpen}
				onClose={() => closeAssignModal()}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				hideBackdrop
			>
				<Sheet
					className="modal-sheet"
					variant="plain"
					sx={{
						padding: "1.7rem 2rem",
						maxWidth: "90%",
						width: "350px",
						borderRadius: "lg",
						boxShadow: "lg"
					}}
				>
					<ModalClose variant="plain" onClick={() => setIsAssignModalOpen(false)} />

					<Typography
						sx={{
							textTransform: "uppercase",
							fontSize: "xs",
							letterSpacing: "lg",
							fontWeight: "lg",
							color: "text.secondary",
							mb: 2
						}}
					>
						Assign team members
					</Typography>

					{isAssignModalOpen && (
						<List size="sm" sx={{ gap: "1rem" }}>
							{contributors.map(contributor => {
								const currentList = lists.find(list => list.id === currentListId);

								const currentItem = currentList
									? currentList.items.find(item => item.id === currentItemId)
									: null;

								const isAlreadyAssigned = currentItem
									? currentItem.assigned_users.some(user => user.username === contributor.username)
									: false;

								return (
									<ListItem
										key={contributor.id}
										sx={{ display: "flex", alignItems: "center", gap: "1rem", padding: 0 }}
									>
										<Avatar size="md" src={contributor.photo || ""}>
											{!contributor.photo &&
												(contributor.display_name || contributor.username)[0].toUpperCase()}
										</Avatar>
										<Stack>
											<Typography>{contributor.display_name || contributor.username}</Typography>
											<Typography level="body-xs">{contributor.email}</Typography>
										</Stack>
										<Checkbox
											color="primary"
											onChange={e => handleCheckboxChange(contributor, e.target.checked)}
											sx={{ ml: "auto" }}
											defaultChecked={isAlreadyAssigned}
										/>
									</ListItem>
								);
							})}
						</List>
					)}
				</Sheet>
			</Modal>

			<Modal
				open={moveModalOpen}
				onClose={() => closeMoveModal()}
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
				hideBackdrop
			>
				<Sheet
					className="modal-sheet"
					variant="plain"
					sx={{
						padding: "1.7rem 2rem",
						maxWidth: "90%",
						width: "350px",
						borderRadius: "lg",
						boxShadow: "lg",
						display: "flex",
						flexDirection: "column",
						gap: "1rem"
					}}
				>
					<Typography
						sx={{
							textTransform: "uppercase",
							fontSize: "sm",
							letterSpacing: "lg",
							fontWeight: "lg",
							color: "text.secondary"
						}}
					>
						Move to
					</Typography>

					<ModalClose variant="plain" onClick={() => closeMoveModal()} />

					{moveModalOpen &&
						lists
							.filter(list => list.id !== currentItem.listId)
							.map(list => (
								<Button
									key={list.id}
									onClick={() => moveItemToList(list.id)}
									color="neutral"
									variant="soft"
								>
									{list.name}
								</Button>
							))}
				</Sheet>
			</Modal>
		</Stack>
	);
}
