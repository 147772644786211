import { useEffect, useState } from "react";
import { getToken } from "../lib/auth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import SideMenu from "./dashboard/SideMenu";
import MyAccount from "./dashboard/MyAccount";
import MyProjects from "./dashboard/MyProjects";
import Tasks from "./dashboard/Tasks";
import SingleProject from "./dashboard/SingleProject";

export default function Dashboard() {
	const navigate = useNavigate();
	const location = useLocation();
	const { projectId } = useParams();
	const [user, setUser] = useState();
	const [activeTab, setActiveTab] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getUserData = async () => {
			try {
				const token = getToken("access");
				if (!token) {
					navigate("/login");
					return;
				}
				const response = await axios.get("/api/auth/my-account/", {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setUser(response.data);
			} catch (error) {
				console.error("Error:", error);
				navigate("/login");
			}
		};

		getUserData();

		if (location.pathname === "/dashboard/" || location.pathname === "/dashboard") {
			navigate("/dashboard/projects");
		}
	}, [navigate, location]);

	useEffect(() => {
		if (user) {
			setIsLoading(false);
		}
	}, [user]);

	useEffect(() => {
		const currentPath = location.pathname.split("/")[2];
		if (currentPath === "projects" && projectId) {
			setActiveTab(null);
		} else {
			setActiveTab(currentPath || "projects");
		}
	}, [location, projectId]);

	return !isLoading ? (
		<main className="dashboard">
			<SideMenu activeTab={activeTab} setActiveTab={setActiveTab} user={user} />
			{/* Interfaces */}
			{activeTab === "projects" && !projectId ? <MyProjects user={user} setActiveTab={setActiveTab} /> : null}
			{projectId ? <SingleProject projectId={projectId} user={user} /> : null}
			{activeTab === "tasks" ? <Tasks user={user} /> : null}
			{activeTab === "account" ? <MyAccount user={user} /> : null}
		</main>
	) : null;
}
