import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getToken } from "../../lib/auth";

import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import Textarea from "@mui/joy/Textarea";

export default function ProjectModal({
	isProjectModalOpen,
	setIsProjectModalOpen,
	project,
	setProjects,
	afterEditProject
}) {
	const navigate = useNavigate();

	const [data, setData] = useState({
		name: project?.name || "",
		description: project?.description || ""
	});

	useEffect(() => {
		setData({
			name: project?.name || "",
			description: project?.description || ""
		});
	}, [project, isProjectModalOpen]);

	const handleInputChange = event => {
		const { name, value } = event.target;
		setData(prevData => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			const token = getToken("access");
			if (!token) navigate("/login");

			let response;
			if (project) {
				response = await axios.put(`/api/projects/${project.id}/`, data, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				if (afterEditProject) {
					afterEditProject(response.data);
				}
			} else {
				response = await axios.post("/api/projects/", data, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
			}

			const newProject = response.data;

			if (setProjects) {
				if (project) {
					setProjects(prevProjects => prevProjects.map(p => (p.id === project.id ? response.data : p)));
				} else {
					setProjects(prevProjects => [...prevProjects, response.data]);
				}
			}

			setData({ name: "", description: "" });
			setIsProjectModalOpen(false);
			navigate(`/dashboard/projects/${newProject.id}`);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<Modal
			open={isProjectModalOpen}
			onClose={() => setIsProjectModalOpen(false)}
			sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<Sheet
				className="modal-sheet"
				variant="plain"
				sx={{
					width: "500px",
					maxWidth: "90%",
					borderRadius: "lg",
					boxShadow: "lg"
				}}
			>
				<ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setIsProjectModalOpen(false)} />

				{!project?.name && (
					<Typography component="h2" level="h4" mb={2}>
						Create a new Project
					</Typography>
				)}

				<form onSubmit={handleSubmit} color="neutral" className="create-project" autoComplete="off">
					<Stack spacing={3}>
						<div>
							<FormLabel>Project Name</FormLabel>
							<Input
								name="name"
								variant="soft"
								color="neutral"
								placeholder="New Project"
								value={data.name}
								onChange={handleInputChange}
								required
							/>
						</div>

						<div>
							<FormLabel>Description</FormLabel>
							<Textarea
								name="description"
								variant="soft"
								color="neutral"
								minRows={3}
								placeholder="This is required to use the AI for feature suggestions. Providing more context will help the AI make better suggestions."
								value={data.description}
								onChange={handleInputChange}
							/>
						</div>

						<Button
							type="submit"
							variant="solid"
							color="primary"
							// style={{ margin: "1.5rem auto 0", padding: "1.25rem 2.5rem", width: "fit-content" }}
							style={{ padding: "0.8rem" }}
						>
							{project ? "Edit Project" : "Create Project"}
						</Button>
					</Stack>
				</form>
			</Sheet>
		</Modal>
	);
}
