import { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../../lib/auth";
import { useNavigate } from "react-router-dom";
import { differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";

import CustomDropdown from "./Dropdown";
import ProjectModal from "./ProjectModal";
import AddPeopleModal from "./AddPeopleModal";
import RemovePeopleModal from "./RemovePeopleModal";

import { Button, CircularProgress, Typography } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import IconButton from "@mui/joy/IconButton";
// eslint-disable-next-line
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListDivider from "@mui/joy/ListDivider";
import Edit from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import DeleteForever from "@mui/icons-material/DeleteForever";
import MenuButton from "@mui/joy/MenuButton";
import Dropdown from "@mui/joy/Dropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Avatar from "@mui/joy/Avatar";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Lists from "./Lists";

function formatUpdatedAt(updatedAt) {
	const now = new Date();
	const updatedDate = new Date(updatedAt);

	const diffInMinutes = differenceInMinutes(now, updatedDate);
	const diffInHours = differenceInHours(now, updatedDate);
	const diffInDays = differenceInDays(now, updatedDate);

	if (diffInMinutes < 1) {
		return "just now";
	} else if (diffInMinutes === 1) {
		return "1 minute ago";
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes} minutes ago`;
	} else if (diffInHours < 24) {
		return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
	} else {
		return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
	}
}

export default function SingleProject({ projectId, user }) {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	const [project, setProject] = useState({});
	const [lists, setLists] = useState([]);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
	const [isAddPeopleModalOpen, setIsAddPeopleModalOpen] = useState(false);
	const [isRemovePeopleModalOpen, setIsRemovePeopleModalOpen] = useState(false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

	const [contributors, setContributors] = useState({ people: [], total: 0 });

	useEffect(() => {
		const getProjectData = async () => {
			try {
				const token = getToken("access");
				const response = await axios.get(`/api/projects/${projectId}/`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				console.log("RESPONSE -> ", response.data);
				setProject(response.data);
				setLists(response.data.lists);
				setIsLoading(false);

				const contributorsData = response.data.contributors;
				setContributors({ people: contributorsData, total: contributorsData.length });
			} catch (error) {
				if (error.response.status === 404) console.log("Project not found");
				console.error("Error:", error);
				navigate("/dashboard/projects");
				return;
			}
		};

		getProjectData();
	}, [projectId, navigate]);

	const handleDeleteProject = async () => {
		try {
			const token = getToken("access");
			const response = await axios.delete(`/api/projects/${projectId}/`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			console.log(response);
			navigate("/dashboard/projects");
			return;
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const afterRemovePeople = updatedContributors => {
		setContributors({ people: updatedContributors, total: updatedContributors.length });
	};

	const afterAddPeople = usersToAdd => {
		setContributors(prev => ({
			people: [...prev.people, ...usersToAdd],
			total: prev.total + usersToAdd.length
		}));
	};

	const afterEditProject = updatedProject => {
		setProject(updatedProject);
	};

	return isLoading ? (
		<CircularProgress
			color="neutral"
			size="sm"
			variant="soft"
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)"
			}}
		/>
	) : (
		<main className="single-project interface">
			<Button
				variant="outlined"
				color="neutral"
				onClick={() => navigate("/dashboard/projects")}
				sx={{ marginBottom: "1rem" }}
			>
				<ArrowBackIcon sx={{ fontSize: "0.75rem", marginRight: "0.5rem", marginLeft: "-0.35rem" }} />
				Back to projects
			</Button>

			<Stack direction="row" spacing={{ xs: "0.65rem", sm: "1rem" }} alignItems="center">
				<Typography level="h2" fontSize={{ xs: "22px", sm: "24px" }} sx={{ cursor: "default" }}>
					{project.name}
				</Typography>

				<Stack direction="row" spacing={0} alignItems="center">
					{/* <IconButton color="neutral">
						<BookmarkAdd />
					</IconButton> */}
					<Dropdown onOpenChange={() => setDropdownOpen(!dropdownOpen)}>
						<MenuButton
							slots={{ root: IconButton }}
							slotProps={{ root: { variant: "plain", color: "neutral" } }}
							sx={{
								backgroundColor: dropdownOpen ? "neutral.softBg" : "transparent",
								"&:hover": { backgroundColor: dropdownOpen ? "neutral.softBg" : "transparent" }
							}}
						>
							<MoreHorizIcon />
						</MenuButton>
						<Menu placement="bottom">
							<MenuItem onClick={() => setIsAddPeopleModalOpen(true)}>
								<ListItemDecorator>
									<GroupAddIcon />
								</ListItemDecorator>
								Add team members
							</MenuItem>
							<MenuItem
								onClick={() => setIsRemovePeopleModalOpen(true)}
								disabled={user.id !== project.owner.id || contributors.total === 0}
							>
								<ListItemDecorator>
									<GroupRemoveIcon />
								</ListItemDecorator>
								Remove team members
							</MenuItem>
							<ListDivider />
							<MenuItem
								onClick={() => setIsProjectModalOpen(true)}
								disabled={user.id !== project.owner.id}
							>
								<ListItemDecorator>
									<Edit />
								</ListItemDecorator>
								Edit project
							</MenuItem>
							<MenuItem
								variant="plain"
								color="danger"
								onClick={() => setDeleteConfirmationOpen(true)}
								disabled={user.id !== project.owner.id}
							>
								<ListItemDecorator sx={{ color: "inherit" }}>
									<DeleteForever />
								</ListItemDecorator>
								Delete project
							</MenuItem>
						</Menu>
					</Dropdown>
				</Stack>
			</Stack>

			<Typography
				level="body-sm"
				fontSize={11}
				sx={{ marginBottom: "0.4rem", marginTop: "0.3rem", cursor: "default" }}
			>
				Last updated <Typography color="primary">{formatUpdatedAt(project.updated_at)}</Typography>
			</Typography>

			{project.description && <CustomDropdown title="Description" content={project.description} isText={true} />}

			{contributors.total > 0 && (
				<CustomDropdown
					title="Team members"
					content={
						<AvatarGroup>
							{contributors.people.slice(0, 5).map(contributor => (
								<Avatar key={contributor.id} src={contributor.photo || ""} size="md">
									{(!contributor.photo && contributor.display_name[0]?.toUpperCase()) ||
										contributor.username[0]?.toUpperCase()}
								</Avatar>
							))}
							{contributors.total > 5 && <Avatar>+{contributors.total - 5}</Avatar>}
						</AvatarGroup>
					}
					isText={false}
				/>
			)}

			<Lists project={project} lists={lists} setLists={setLists} contributors={contributors.people} />

			{/* MODALS */}
			<ProjectModal
				isProjectModalOpen={isProjectModalOpen}
				setIsProjectModalOpen={setIsProjectModalOpen}
				project={project}
				afterEditProject={afterEditProject}
			/>
			<AddPeopleModal
				isAddPeopleModalOpen={isAddPeopleModalOpen}
				setIsAddPeopleModalOpen={setIsAddPeopleModalOpen}
				projectId={projectId}
				afterAddPeople={afterAddPeople}
				contributors={contributors}
				projectOwner={project.owner}
			/>
			<RemovePeopleModal
				isRemovePeopleModalOpen={isRemovePeopleModalOpen}
				setIsRemovePeopleModalOpen={setIsRemovePeopleModalOpen}
				project={project}
				afterRemovePeople={afterRemovePeople}
				contributors={contributors.people}
			/>
			<Modal
				className="delete-project-modal"
				open={deleteConfirmationOpen}
				onClose={() => setDeleteConfirmationOpen(false)}
				hideBackdrop={true}
				disableAutoFocus={true}
			>
				<ModalDialog variant="outlined" role="alertdialog">
					<DialogTitle>
						<WarningRoundedIcon color="neutral" />
						Confirmation
					</DialogTitle>
					<Divider />
					<DialogContent>
						<p>
							This will permanently delete <b>{project.name}</b> and any team member associations with the
							project will be removed.
						</p>

						<p style={{ fontSize: "12px" }}>
							Please ensure you have backed up any essential data before proceeding.
						</p>
					</DialogContent>
					<DialogActions>
						<Button variant="solid" color="danger" onClick={() => handleDeleteProject()}>
							Delete
						</Button>
						<Button variant="plain" color="neutral" onClick={() => setDeleteConfirmationOpen(false)}>
							Cancel
						</Button>
					</DialogActions>
				</ModalDialog>
			</Modal>
		</main>
	);
}
