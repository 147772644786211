import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/joy/styles";

const CustomMenuItem = styled(MenuItem)`
  &:focus-visible {
    outline: none;
  }
`;

export default function AvatarMenu() {
	const navigate = useNavigate();

	const handleLogOut = () => {
		localStorage.removeItem("access");
		navigate("/");
	};

	return (
		<Menu placement="bottom-start" size="md">
			{/* <MenuItem>My account</MenuItem> */}
			<CustomMenuItem>Settings</CustomMenuItem>
			<CustomMenuItem>Invite users</CustomMenuItem>
			<CustomMenuItem onClick={handleLogOut}>
				Log out
			</CustomMenuItem>
		</Menu>
	);
}
