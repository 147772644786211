import React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Logo from "../img/koncano.png";
import LogoDark from "../img/koncano-dark.png";
import { Link, useNavigate } from "react-router-dom";
import { useColorScheme } from "@mui/joy/styles";

export default function Header() {
	const navigate = useNavigate();

	function navigateCreateAccount() {
		navigate("/create-account");
	}

	function navigateLogin() {
		navigate("/login");
	}

	const { mode } = useColorScheme();

	return (
		<header>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Link to="/">
					<img src={mode === "light" ? Logo : LogoDark} alt="Koncano logo" />
				</Link>
				<ul>
					<li>
						<Link to="features">Features</Link>
					</li>
					<li>
						<Link>Changelog</Link>
					</li>
					<li>
						<Link>Support</Link>
					</li>
				</ul>
				<Stack direction="row">
					<Button aria-label="Create an account" variant="plain" onClick={navigateLogin}>
						Login
					</Button>
					<Button
						className="sign-up-button"
						aria-label="Create an account"
						variant="solid"
						onClick={navigateCreateAccount}
					>
						Sign up
					</Button>
				</Stack>
			</Stack>
		</header>
	);
}
