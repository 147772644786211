import React from "react";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import ThemeToggle from "./dashboard/ThemeToggle";
import HeroImg from "../img/hero-image.png";
import DotOverlay from "../img/dot-overlay.png";
import HeroMobile from "../img/koncano-prototype-mobile.png";

export default function Home() {
	const navigate = useNavigate();

	function navigateCreateAccount() {
		navigate("/create-account");
	}

	return (
		<>
			<Header />
			<main className="home">
				<Stack direction="column" alignItems="center" className="hero">
					{/* <h1>Designed to unearth potential and streamline success</h1> */}
					<h1>Powered by AI to bring you a project planning experience like no other</h1>
					{/* <h1>Powered by AI to Bring You a Project Planning Experience Like No Other</h1> */}
					<p className="subtitle">Koncano guides you to a fully-fledged project plan in minutes.</p>
					<Stack direction="row" spacing={2}>
						<Button size="lg" onClick={navigateCreateAccount}>
							Get started
						</Button>
						<Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
							<ThemeToggle />
						</Stack>
					</Stack>
					<img src={HeroImg} alt="Koncano hero" className="hero-img" />
					<img src={HeroMobile} alt="Koncano hero" className="hero-img-mobile" />
				</Stack>
				<img src={DotOverlay} className="dot-overlay overlay" alt="Dot overlay background" />
				{/* <div className="gradient-overlay overlay"></div> */}
			</main>
		</>
	);
}
