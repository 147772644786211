import { Modal, Typography, Sheet, ModalClose, Stack, Grid, Avatar, Button, Divider } from "@mui/joy";
import SearchBar from "../SearchBar";
import { useState, useEffect } from "react";
import axios from "axios";
import AvatarGroup from "@mui/joy/AvatarGroup";
import { getToken } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

export default function AddPeopleModal({
	isAddPeopleModalOpen,
	setIsAddPeopleModalOpen,
	projectId,
	afterAddPeople,
	contributors,
	projectOwner
}) {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState("");
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [usersToAdd, setUsersToAdd] = useState([]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get("/api/auth/users/");
				setAllUsers(response.data);
			} catch (error) {
				console.error("Error fetching users:", error);
			}
		};

		fetchUsers();
	}, []);

	useEffect(() => {
		if (searchValue.length >= 2) {
			const results = allUsers.filter(user => {
				const isUsernameOrEmailMatch = user.username.includes(searchValue) || user.email.includes(searchValue);
				const isAlreadyContributor = contributors.people.find(addedUser => addedUser.id === user.id);
				const isProjectOwner = user.id === projectOwner.id;

				return isUsernameOrEmailMatch && !isAlreadyContributor && !isProjectOwner;
			});

			const exactMatches = results.filter(user => user.username === searchValue || user.email === searchValue);
			const partialMatches = results.filter(user => user.username !== searchValue && user.email !== searchValue);

			setFilteredUsers([...exactMatches, ...partialMatches]);
		} else {
			setFilteredUsers([]);
		}
	}, [searchValue, allUsers, contributors.people, projectOwner]);

	const handleUserClick = user => {
		setSearchValue("");

		const existingUser = usersToAdd.find(u => u.id === user.id);

		if (!existingUser) {
			setUsersToAdd([...usersToAdd, user]);
			setFilteredUsers(prevFilteredUsers => prevFilteredUsers.filter(prevUser => prevUser.id !== user.id));
		} else {
			console.log("User already added");
		}
	};

	const clampAvatars = (avatars, options = { max: 3, total: 0 }) => {
		const { max, total } = options;
		let clampedMax = max < 2 ? 2 : max;
		const totalAvatars = total || avatars.length;
		if (totalAvatars === clampedMax) {
			clampedMax += 1;
		}
		clampedMax = Math.min(totalAvatars + 1, clampedMax);
		const maxAvatars = Math.min(avatars.length, clampedMax - 1);
		const surplus = Math.max(totalAvatars - clampedMax, totalAvatars - maxAvatars, 0);
		return { avatars: avatars.slice(0, maxAvatars).reverse(), surplus };
	};

	const { avatars, surplus } = clampAvatars(usersToAdd, {
		max: 7,
		total: usersToAdd.length
	});

	const handleSubmit = async () => {
		try {
			const token = getToken("access");

			if (!token) navigate("/login");

			for (const user of usersToAdd) {
				await axios.post(
					`/api/projects/${projectId}/add-contributor/`,
					{ username: user.username },
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				);
			}

			afterAddPeople(usersToAdd);
			setUsersToAdd([]);
			setIsAddPeopleModalOpen(false);
		} catch (error) {
			console.error("Error adding contributors:", error);
		}
	};

	return (
		<Modal
			open={isAddPeopleModalOpen}
			onClose={() => setIsAddPeopleModalOpen(false)}
			sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<Sheet
				sx={{ p: 3.5, maxWidth: "90%", width: "350px", borderRadius: "lg", boxShadow: "lg" }}
				className="modal-sheet"
				variant="plain"
			>
				<ModalClose
					variant="plain"
					sx={{ m: 1 }}
					onClick={() => {
						setUsersToAdd([]);
						setIsAddPeopleModalOpen(false);
					}}
				/>
				<Typography
					sx={{
						textTransform: "uppercase",
						fontSize: "xs",
						letterSpacing: "lg",
						fontWeight: "lg",
						color: "text.secondary",
						mb: 2
					}}
				>
					Add team members
				</Typography>

				<Stack spacing={1.5}>
					<SearchBar
						placeholder={usersToAdd.length > 0 ? "Find more people to add" : "Search by email or username"}
						searchValue={searchValue}
						onSearchChange={setSearchValue}
					/>

					{/* MAKE FLEX WRAP RATHER THAN GRID */}
					{filteredUsers.length > 0 && (
						<Grid container spacing={2}>
							{filteredUsers
								.filter(
									user =>
										!usersToAdd.find(addedUser => addedUser.id === user.id) ||
										!contributors.people.find(addedUser => addedUser.id === user.id)
								)
								.map(user => (
									<Grid xs={2} key={`result-${user.id}`}>
										<Stack
											spacing={0.5}
											sx={{
												cursor: "pointer",
												justifyContent: "center",
												alignItems: "center",
												width: "fit-content"
											}}
											onClick={() => handleUserClick(user)}
										>
											<Avatar size="md" src={user.photo || ""}>
												{!user.photo && (user.display_name || user.username)[0].toUpperCase()}
											</Avatar>
											<Typography level="body-sm" sx={{ textAlign: "center" }}>
												{user.display_name || user.username}
											</Typography>
										</Stack>
									</Grid>
								))}
						</Grid>
					)}

					{usersToAdd.length > 0 && filteredUsers.length > 0 ? <Divider /> : null}

					{usersToAdd.length > 0 && (
						<AvatarGroup>
							{avatars.map(avatar => (
								<Avatar key={avatar.id} src={avatar.photo || ""} size="md">
									{!avatar.photo && (avatar.display_name || avatar.username)[0].toUpperCase()}
								</Avatar>
							))}
							{!!surplus && <Avatar>+{surplus}</Avatar>}
						</AvatarGroup>
					)}

					<Button
						disabled={usersToAdd.length > 0 ? false : true}
						variant="solid"
						color="primary"
						sx={{ width: "100%" }}
						onClick={() => {
							handleSubmit();
						}}
					>
						{usersToAdd.length === 1
							? `Add ${usersToAdd[0].display_name ? usersToAdd[0].display_name : usersToAdd[0].username}`
							: usersToAdd.length > 1
							? `Add ${usersToAdd.length} people`
							: "Search for people to add"}
					</Button>
				</Stack>
			</Sheet>
		</Modal>
	);
}
