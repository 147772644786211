import * as React from "react";
import Switch, { switchClasses } from "@mui/joy/Switch";
import { useColorScheme } from "@mui/joy/styles";
import { useState, useEffect } from "react";

export default function ThemeToggle() {
	const [checked, setChecked] = useState(false);

	const { mode, setMode } = useColorScheme();

	const handleThemeChange = () => {
		setChecked(!checked);
		setMode(mode === "dark" ? "light" : "dark");
	};

	// if theme is dark set unchecked, if theme is light set checked
	useEffect(() => {
		if (mode === "dark") {
			setChecked(false);
		} else {
			setChecked(true);
		}
	}, [mode]);

	return (
		<Switch
			checked={checked}
			onChange={() => handleThemeChange()}
			slotProps={{
				thumb: {
					children: !checked ? (
						<svg viewBox="0 0 24 24" height="20px" width="20px">
							<title />
							<path
								d="M20.21,15.32A8.56,8.56,0,1,1,11.29,3.5a.5.5,0,0,1,.51.28.49.49,0,0,1-.09.57A6.46,6.46,0,0,0,9.8,9a6.57,6.57,0,0,0,9.71,5.72.52.52,0,0,1,.58.07A.52.52,0,0,1,20.21,15.32Z"
								fill="#464646"
								// fill="#ffffff"
							/>
						</svg>
					) : (
						<svg height="20px" viewBox="0 0 512 512" width="20px">
							<path
								d="M256,144c-61.75,0-112,50.25-112,112s50.25,112,112,112s112-50.25,112-112S317.75,144,256,144z M256,336    c-44.188,0-80-35.812-80-80c0-44.188,35.812-80,80-80c44.188,0,80,35.812,80,80C336,300.188,300.188,336,256,336z M256,112    c8.833,0,16-7.167,16-16V64c0-8.833-7.167-16-16-16s-16,7.167-16,16v32C240,104.833,247.167,112,256,112z M256,400    c-8.833,0-16,7.167-16,16v32c0,8.833,7.167,16,16,16s16-7.167,16-16v-32C272,407.167,264.833,400,256,400z M380.438,154.167    l22.625-22.625c6.25-6.25,6.25-16.375,0-22.625s-16.375-6.25-22.625,0l-22.625,22.625c-6.25,6.25-6.25,16.375,0,22.625    S374.188,160.417,380.438,154.167z M131.562,357.834l-22.625,22.625c-6.25,6.249-6.25,16.374,0,22.624s16.375,6.25,22.625,0    l22.625-22.624c6.25-6.271,6.25-16.376,0-22.625C147.938,351.583,137.812,351.562,131.562,357.834z M112,256    c0-8.833-7.167-16-16-16H64c-8.833,0-16,7.167-16,16s7.167,16,16,16h32C104.833,272,112,264.833,112,256z M448,240h-32    c-8.833,0-16,7.167-16,16s7.167,16,16,16h32c8.833,0,16-7.167,16-16S456.833,240,448,240z M131.541,154.167    c6.251,6.25,16.376,6.25,22.625,0c6.251-6.25,6.251-16.375,0-22.625l-22.625-22.625c-6.25-6.25-16.374-6.25-22.625,0    c-6.25,6.25-6.25,16.375,0,22.625L131.541,154.167z M380.459,357.812c-6.271-6.25-16.376-6.25-22.625,0    c-6.251,6.25-6.271,16.375,0,22.625l22.625,22.625c6.249,6.25,16.374,6.25,22.624,0s6.25-16.375,0-22.625L380.459,357.812z"
								fill="#1D1D1B"
							/>
						</svg>
					)
				}
			}}
			sx={theme => ({
				"--Switch-thumbShadow": "0 3px 7px 0 rgba(0 0 0 / 0.12)",
				// "--Switch-thumbSize": "18px",
				"--Switch-thumbSize": "17px",
				// "--Switch-thumbBackground": "white",
				"--Switch-trackWidth": "40px",
				"--Switch-trackHeight": "22px",
				"--Switch-trackBackground": theme.vars.palette.neutral.softBg,
				[`& .${switchClasses.thumb}`]: {
					transition: "width 0.2s, left 0.2s"
				},
				"&:hover": {
					"--Switch-trackBackground": theme.vars.palette.neutral.softBg
				},
				"&:active": {
					// "--Switch-thumbWidth": "22px"
					"--Switch-thumbWidth": "25px"
				},
				[`&.${switchClasses.checked}`]: {
					"--Switch-trackBackground": theme.vars.palette.neutral.softBg,
					"&:hover": {
						"--Switch-trackBackground": theme.vars.palette.neutral.softBg
					}
				}
			})}
		/>
	);
}
