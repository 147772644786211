import Home from "./components/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Dashboard from "./components/Dashboard";
import CreateAccount from "./components/CreateAccount";
import Login from "./components/Login";

export default function App() {
	const theme = extendTheme({
		typography: {
			htmlFontSize: 14
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 590,
				md: 1050,
				lg: 1400,
				xl: 1920
			}
		},
		components: {
			JoyButton: {
				styleOverrides: {
					root: {
						"&:active": {
							transform: "translateY(1.5px)",
							"@media (max-width: 499px)": {
								transform: "translateY(6px)"
							}
						}
						// Media query max width 500px
					}
				}
			}
		},
		colorSchemes: {
			light: {
				palette: {
					text: {
						primary: "#181819",
						secondary: "#181819"
					},
					primary: {
						50: "#E8E6EC",
						100: "#E8E6EC",
						200: "#9171FC",
						300: "#8A69FC",
						400: "#8360FB",
						500: "#8360FB",
						600: "#8360FB",
						700: "#8360FB",
						800: "#1C1528",
						// solidBg: "var(--joy-palette-primary-500)",
						solidHoverBg: "var(--joy-palette-primary-200)",
						solidActiveBg: "var(--joy-palette-primary-200)",
						outlinedHoverBg: "#fff",
						outlinedActiveBg: "transparent",
						plainHoverBg: "transparent",
						plainActiveBg: "transparent"
						// outlinedHoverColor: "#fff",
					},
					grey: {
						primary: "#f4f4f4",
						softBg: "#f4f4f4",
						plainHoverBg: "#f4f4f4"
						// primary: "#fbfcfe",
						// softBg: "#fbfcfe",
						// plainHoverBg: "#fbfcfe"
					},
					listItemButton: {
						// plainHoverBg: "#f4f3f450",
						plainHoverBg: "transparent"
					},
					neutral: {
						plainActiveBg: "none",
						outlinedActiveBg: "var(--joy-palette-neutral-outlinedHoverBg)",
						softHoverBg: "var(--joy-palette-neutral-softBg)",
						softActiveBg: "var(--joy-palette-neutral-softBg)"
					},
					danger: {
						plainActiveBg: "none"
					}
				}
			},
			dark: {
				palette: {
					primary: {
						50: "#E8E6EC",
						100: "#E8E6EC",
						200: "#9171FC",
						300: "#8A69FC",
						400: "#8360FB",
						500: "#8360FB",
						600: "#8360FB",
						700: "#8360FB",
						800: "#1C1528"
					},
					neutral: {
						plainActiveBg: "none",
						outlinedActiveBg: "var(--joy-palette-neutral-outlinedHoverBg)",
						softHoverBg: "var(--joy-palette-neutral-softBg)",
						softActiveBg: "var(--joy-palette-neutral-softBg)"
					},
					danger: {
						plainActiveBg: "none"
					}
				}
			}
		}
	});

	return (
		<CssVarsProvider theme={theme} defaultMode="light">
			<CssBaseline />

			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/create-account" element={<CreateAccount />} />
					<Route path="/login" element={<Login />} />
					<Route path="/dashboard/projects/:projectId" element={<Dashboard />} />
					<Route path="/dashboard/*" element={<Dashboard />} />
					<Route path="*" element={<Home />} />
				</Routes>
			</BrowserRouter>
		</CssVarsProvider>
	);
}
