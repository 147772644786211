import Table from "@mui/joy/Table";
import axios from "axios";
import { useEffect, useState } from "react";
import { getToken } from "../../lib/auth";
import { differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";

function formatUpdatedAt(updatedAt) {
	const now = new Date();
	const updatedDate = new Date(updatedAt);

	const diffInMinutes = differenceInMinutes(now, updatedDate);
	const diffInHours = differenceInHours(now, updatedDate);
	const diffInDays = differenceInDays(now, updatedDate);

	if (diffInMinutes < 1) {
		return "just now";
	} else if (diffInMinutes === 1) {
		return "1 minute ago";
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes} minutes ago`;
	} else if (diffInHours < 24) {
		return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
	} else {
		return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
	}
}

export default function Tasks(user) {
	const [projects, setProjects] = useState([]);
	const [lists, setLists] = useState([]);
	const [rows, setRows] = useState([]);

	const getProjects = async () => {
		try {
			const token = getToken("access");
			const response = await axios.get("/api/projects/", {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			setProjects(response.data);
			getListsFromProjects(response.data);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const getListsFromProjects = async projects => {
		try {
			const token = getToken("access");
			let listsData = [];

			for (let project of projects) {
				const response = await axios.get(`/api/projects/${project.id}/lists/`, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				listsData = [...listsData, ...response.data];
			}

			setLists(listsData);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	useEffect(() => {
		getProjects();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		console.log(projects);
		console.log(lists);
	}, [projects, lists]);

	useEffect(() => {
		const newRows = lists
			.filter(list => list.name !== "Features" && list.name !== "In Progress" && list.name !== "Completed")
			.flatMap(list =>
				[...list.items].reverse().map(item => ({
					content: item.content,
					listName: list.name,
					createdAt: formatUpdatedAt(item.created_at)
				}))
			);

		setRows(newRows);
	}, [lists]);

	return (
		<Stack className="tasks-page" spacing={1.5}>
			<Typography component="h4" sx={{ textTransform: "uppercase" }}>
				Tasks
			</Typography>
			<Typography level="body-sm">This is a list of your tasks across all your projects.</Typography>
			<Table borderAxis="xBetween">
				<thead>
					<tr>
						<th>Task</th>
						<th style={{ width: "20%" }}>List</th>
						<th style={{ width: "20%" }}>Created</th>
					</tr>
				</thead>
				<tbody>
					{rows.map((row, index) => (
						<tr key={index}>
							<td>{row.content}</td>
							<td>{row.listName}</td>
							<td>{row.createdAt}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Stack>
	);
}
