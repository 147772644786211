import {
	Modal,
	ModalClose,
	Sheet,
	Typography,
	Stack,
	List,
	ListItem,
	Checkbox,
	checkboxClasses,
	Avatar,
	Button
} from "@mui/joy";
import { useState } from "react";
import axios from "axios";
import { getToken } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

export default function RemovePeopleModal({
	isRemovePeopleModalOpen,
	setIsRemovePeopleModalOpen,
	project,
	afterRemovePeople,
	contributors
}) {
	const [selectedUsers, setSelectedUsers] = useState([]);

	const navigate = useNavigate();

	const handleCheckboxChange = (user, isChecked) => {
		if (!user || !user.id) return;

		if (isChecked) {
			if (selectedUsers.find(selectedUser => selectedUser.id === user.id)) {
				return;
			}
			setSelectedUsers(prev => [...prev, user]);
		} else {
			setSelectedUsers(prev => prev.filter(selectedUser => selectedUser.id !== user.id));
		}
	};

	const handleRemoveMembers = async () => {
		try {
			const token = getToken("access");

			if (!token) navigate("/login");

			for (const user of selectedUsers) {
				await axios.post(
					`/api/projects/${project.id}/remove-contributor/`,
					{ username: user.username },
					{
						headers: {
							Authorization: `Bearer ${token}`
						}
					}
				);
			}

			const removedUserIds = selectedUsers.map(user => user.id);
			const updatedContributors = contributors.filter(contributor => !removedUserIds.includes(contributor.id));

			afterRemovePeople(updatedContributors);

			setSelectedUsers([]);
			setIsRemovePeopleModalOpen(false);
		} catch (error) {
			console.error("Error removing contributors:", error);
		}
	};

	return (
		<Modal
			open={isRemovePeopleModalOpen}
			onClose={() => setIsRemovePeopleModalOpen(false)}
			sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
		>
			<Sheet
				sx={{ p: 3.5, maxWidth: "90%", width: "350px", borderRadius: "lg", boxShadow: "lg" }}
				className="modal-sheet"
				variant="plain"
			>
				<ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setIsRemovePeopleModalOpen(false)} />
				<Typography
					sx={{
						textTransform: "uppercase",
						fontSize: "xs",
						letterSpacing: "lg",
						fontWeight: "lg",
						color: "text.secondary",
						mb: 2
					}}
				>
					Remove team members
				</Typography>

				<Stack spacing={2}>
					<Stack role="group">
						<List
							size="sm"
							sx={{
								"--ListItem-gap": "0.75rem",
								[`& .${checkboxClasses.root}`]: {
									mr: "auto",
									flexGrow: 1,
									alignItems: "center",
									flexDirection: "row-reverse"
								}
							}}
						>
							{contributors.map(contributor => (
								<ListItem key={contributor.id}>
									<Avatar size="lg" src={contributor.photo || ""}>
										{!contributor.photo &&
											(contributor.display_name || contributor.username)[0].toUpperCase()}
									</Avatar>
									<Checkbox
										label={
											<Stack>
												<Typography>
													{contributor.display_name || contributor.username}
												</Typography>
												<Typography level="body-xs">{contributor.email}</Typography>
											</Stack>
										}
										color="danger"
										onChange={e => handleCheckboxChange(contributor, e.target.checked)}
									/>
								</ListItem>
							))}
						</List>
					</Stack>

					<Button
						disabled={!selectedUsers.length}
						variant="solid"
						color="danger"
						sx={{ width: "100%" }}
						onClick={() => handleRemoveMembers()}
					>
						{!selectedUsers.length && "Select people to remove"}
						{selectedUsers.length === 1 &&
							`Remove ${selectedUsers[0].display_name || selectedUsers[0].username}`}
						{selectedUsers.length > 1 && `Remove ${selectedUsers.length} people`}
					</Button>
				</Stack>
			</Sheet>
		</Modal>
	);
}
