import Stack from "@mui/joy/Stack";
// import Divider from "@mui/joy/Divider";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import AccordionSummary from "@mui/joy/AccordionSummary";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Avatar from "@mui/joy/Avatar";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import AvatarMenu from "./AvatarMenu";
import { useNavigate } from "react-router-dom";
import usePalette from "../UsePalette";
import ThemeToggle from "./ThemeToggle";

export default function SideMenu({ activeTab, setActiveTab, user }) {
	const palette = usePalette();

	const navigate = useNavigate();

	const handleClick = tabName => {
		setActiveTab(tabName);
		navigate(`/dashboard/${tabName}`);
	};

	return (
		<Stack
			className="side-menu-container"
			spacing={3}
			sx={{ borderRight: `1px solid ${palette.neutral.outlinedBorder}` }}
		>
			<Stack
				className="avatar-toggle-container"
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Dropdown>
					<MenuButton
						slots={{ root: IconButton }}
						sx={{ width: "0", borderRadius: "100%" }}
						className="avatar-container"
					>
						<Avatar color="neutral" size="lg" src={user?.photo ? user.photo : ""}>
							{user?.photo
								? null
								: user?.display_name
								? user.display_name.toUpperCase()[0]
								: user?.username
								? user.username.toUpperCase()[0]
								: null}
						</Avatar>
					</MenuButton>
					<AvatarMenu />
				</Dropdown>

				<div className="theme-toggle-desktop">
					<ThemeToggle />
				</div>
			</Stack>
			<ul>
				<li>
					<button
						className={activeTab === "projects" ? "active" : ""}
						onClick={() => handleClick("projects")}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-layout-grid"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="#4b494e"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
							<path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
							<path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
							<path d="M14 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
						</svg>
						<span>Projects</span>
					</button>
				</li>
				<li>
					<button className={activeTab === "tasks" ? "active" : ""} onClick={() => handleClick("tasks")}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-checklist"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="#4B494E"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" />
							<path d="M14 19l2 2l4 -4" />
							<path d="M9 8h4" />
							<path d="M9 12h2" />
						</svg>
						<span>Tasks</span>
					</button>
				</li>
				<li>
					<button className={activeTab === "account" ? "active" : "account-button"} onClick={() => handleClick("account")}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-user-cog"
							width="20"
							height="20"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="#4B494E"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
							<path d="M6 21v-2a4 4 0 0 1 4 -4h2.5" />
							<path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
							<path d="M19.001 15.5v1.5" />
							<path d="M19.001 21v1.5" />
							<path d="M22.032 17.25l-1.299 .75" />
							<path d="M17.27 20l-1.3 .75" />
							<path d="M15.97 17.25l1.3 .75" />
							<path d="M20.733 20l1.3 .75" />
						</svg>
						<span>Account</span>
					</button>
				</li>
			</ul>
			<AccordionGroup
				size="sm"
				transition="0.2s ease-out"
				sx={{
					maxWidth: 400,
					[`& .${accordionClasses.root}`]: {
						"& button:hover": {
							background: "transparent"
						}
					},
					'& [aria-expanded="true"]': {
						boxShadow: theme => `inset 0 -1px 0 ${theme.vars.palette.divider}`
					}
				}}
				className="accordion-group"
			>
				<Accordion className="accordion">
					<AccordionSummary>Bookmarks</AccordionSummary>
					<AccordionDetails>
						<List size="sm">
							<ListItem>
								<ListItemButton color="listItemButton">
									<ListItemContent>Bookmarked Project</ListItemContent>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="icon icon-tabler icon-tabler-chevron-right"
										width="15"
										height="15"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="#4B494E"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<path d="M9 6l6 6l-6 6" />
									</svg>
								</ListItemButton>
							</ListItem>
						</List>
					</AccordionDetails>
				</Accordion>
			</AccordionGroup>

			<div className="bottom-container">
				<div className="theme-toggle-mobile-container">
					<ThemeToggle className="theme-toggle-mobile" />
				</div>

				<a href="mailto: help@koncano.com" style={{ textDecoration: "none" }}>
					<Stack className="help-container" direction="row" spacing={1} alignItems="center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-mail"
							width="15"
							height="15"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="#4B494E"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
							<path d="M3 7l9 6l9 -6" />
						</svg>

						<Typography fontSize="sm">help@koncano.com</Typography>
					</Stack>
				</a>
			</div>
		</Stack>
	);
}
