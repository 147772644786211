// import Switch from "@mui/joy/Switch";
import { useEffect, useState, useRef } from "react";
import { getToken } from "../../lib/auth";

import axios from "axios";

import { Link } from "react-router-dom";
import { differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";

import Logo from "../../img/koncano-logo.png";

import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Card from "@mui/joy/Card";
// eslint-disable-next-line
import IconButton from "@mui/joy/IconButton";
// eslint-disable-next-line
import BookmarkAdd from "@mui/icons-material/BookmarkAddOutlined";
import AvatarGroup from "@mui/joy/AvatarGroup";
import Avatar from "@mui/joy/Avatar";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
// import CircularProgress from "@mui/joy/CircularProgress";

import ProjectModal from "./ProjectModal";

function formatUpdatedAt(updatedAt) {
	const now = new Date();
	const updatedDate = new Date(updatedAt);

	const diffInMinutes = differenceInMinutes(now, updatedDate);
	const diffInHours = differenceInHours(now, updatedDate);
	const diffInDays = differenceInDays(now, updatedDate);

	if (diffInMinutes < 1) {
		return "just now";
	} else if (diffInMinutes === 1) {
		return "1 minute ago";
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes} minutes ago`;
	} else if (diffInHours < 24) {
		return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
	} else {
		return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
	}
}

export default function MyProjects({ user }) {
	const [projects, setProjects] = useState([]);
	const [filters] = useState({
		showOnlyCreated: false,
		showOnlyContributed: false
	});

	const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const placeholderRef = useRef(null);

	useEffect(() => {
		const getProjects = async () => {
			try {
				const token = getToken("access");
				const response = await axios.get("/api/projects/", {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});
				setProjects(response.data);
				setIsLoading(false);
			} catch (error) {
				console.error("Error:", error);
			}
		};

		getProjects();
	}, []);

	useEffect(() => {
		if (placeholderRef.current) {
			const lastCard = document.querySelector(".card:last-of-type");
			if (lastCard) {
				const lastCardHeight = lastCard.offsetHeight;
				placeholderRef.current.style.height = `${lastCardHeight}px`;
			}
		}
	}, [projects]);

	const filteredProjects = projects.filter(project => {
		if (filters.showOnlyCreated && project.owner.username !== user.username) {
			return false;
		}
		if (filters.showOnlyContributed && project.owner.username === user.username) {
			return false;
		}
		return true;
	});

	if (isLoading) {
		return null;
	}

	return (
		<main className="my-projects interface">
			{projects.length > 0 ? (
				<Grid container spacing={{ xs: 2, xl: 3 }} sx={{ transform: "translateY(10px)" }}>
					<Grid xs={12} sm={6} md={4} lg={3}>
						<div onClick={() => setIsProjectModalOpen(true)}>
							<Card
								className="placeholder-card"
								sx={{ overflow: "auto", cursor: "pointer" }}
								color="neutral"
								ref={placeholderRef}
							>
								<Stack justifyContent="center" alignItems="center" width="100%" height="100%">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="icon icon-tabler icon-tabler-plus"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="2.5"
										stroke="var(--joy-palette-neutral-500)"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none" />
										<path d="M12 5l0 14" />
										<path d="M5 12l14 0" />
									</svg>
									<Typography level="body-sm" fontSize={12} marginTop={0.5}>
										Create a new project
									</Typography>
								</Stack>
							</Card>
						</div>
					</Grid>
					{[...filteredProjects].reverse().map(project => (
						<Grid key={project.id} xs={12} sm={6} md={4} lg={3}>
							<Link to={`/dashboard/projects/${project.id}`} style={{ textDecoration: "none" }}>
								<Card className="card" sx={{ overflow: "auto", cursor: "pointer" }} color="neutral">
									{/* <IconButton
										color="neutral"
										size="sm"
										sx={{ position: "absolute", top: "0.77rem", right: "0.5rem" }}
									>
										<BookmarkAdd />
									</IconButton> */}

									<Typography level="title-lg" width="85%">
										{project.name}
									</Typography>

									<Typography
										className="description"
										level="body-sm"
										fontSize={10}
										sx={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											display: "-webkit-box",
											WebkitLineClamp: 1,
											WebkitBoxOrient: "vertical"
										}}
									>
										{project.description ? project.description : "No description"}
									</Typography>

									{project.owner.username !== user.username || project.contributors.length > 0 ? (
										<AvatarGroup color="neutral" variant="soft">
											{project.owner.username !== user.username && (
												<Avatar key={project.owner.id} src={project.owner.photo}>
													{
														(
															project.owner.display_name || project.owner.username
														).toUpperCase()[0]
													}
												</Avatar>
											)}
											{project.contributors.map(contributor => {
												if (contributor.username !== user.username) {
													return (
														<Avatar key={contributor.id} src={contributor.photo}>
															{
																(
																	contributor.display_name || contributor.username
																).toUpperCase()[0]
															}
														</Avatar>
													);
												} else {
													return null;
												}
											})}
										</AvatarGroup>
									) : (
										<div className="spacer"></div>
									)}

									<Typography level="body-sm" fontSize={10} marginBottom={-1}>
										Created by{" "}
										{project.owner.username === user.username
											? "you"
											: project.owner.username.charAt(0).toUpperCase() +
											  project.owner.username.slice(1)}
									</Typography>
									<Typography level="body-sm" fontSize={10}>
										Last updated{" "}
										<Typography color="primary">{formatUpdatedAt(project.updated_at)}</Typography>
									</Typography>
								</Card>
							</Link>
						</Grid>
					))}
				</Grid>
			) : (
				<Stack
					sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
					justifyContent="center"
					alignItems="center"
					spacing={3}
				>
					<img src={Logo} alt="Koncano logo" width={40} />
					{/* <Typography level="body-sm">Let's create your first project.</Typography> */}
					<Button
						onClick={() => setIsProjectModalOpen(true)}
						sx={{ borderRadius: "40px", padding: "1.25rem" }}
					>
						Create a Project
					</Button>
				</Stack>
			)}

			<ProjectModal
				isProjectModalOpen={isProjectModalOpen}
				setIsProjectModalOpen={setIsProjectModalOpen}
				projects={projects}
				setProjects={setProjects}
			/>
		</main>
	);
}
