import * as React from "react";
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import { styled } from "@mui/joy/styles";
import Link from "@mui/joy/Link";
import axios from "axios";
import { useState, useEffect } from "react";
import { setToken } from "../lib/auth";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
// import animationData from "../img/animation_lnjnopx0.json";
import { useNavigate } from "react-router-dom";

const StyledInput = styled("input")({
	border: 0,
	minWidth: 0,
	outline: 0,
	padding: "0.8em 0",
	flex: 1,
	color: "inherit",
	backgroundColor: "transparent",
	fontFamily: "inherit",
	fontSize: "inherit",
	fontStyle: "inherit",
	fontWeight: "inherit",
	lineHeight: "inherit",
	textOverflow: "ellipsis",
	"&::placeholder": {
		opacity: 0,
		transition: "0.1s ease-out"
	},
	"&:focus::placeholder": {
		opacity: 1
	},
	"&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label": {
		top: "0.7rem",
		fontSize: "0.65rem"
	},
	"&:focus ~ label": {
		color: "var(--Input-focusedHighlight)"
	},
	"&:-webkit-autofill": {
		alignSelf: "stretch" // to fill the height of the root slot
	},
	"&:-webkit-autofill:not(* + &)": {
		marginInlineStart: "calc(-1 * var(--Input-paddingInline))",
		paddingInlineStart: "var(--Input-paddingInline)",
		borderTopLeftRadius: "calc(var(--Input-radius) - var(--variant-borderWidth, 0px))",
		borderBottomLeftRadius: "calc(var(--Input-radius) - var(--variant-borderWidth, 0px))"
	}
});

const StyledLabel = styled("label")(({ theme }) => ({
	position: "absolute",
	lineHeight: 1,
	// top: "calc((var(--Input-minHeight) - 1em) / 2)",
	top: "50%",
	transform: "translateY(-50%)",
	color: theme.vars.palette.text.tertiary,
	fontWeight: theme.vars.fontWeight.md,
	transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)"
}));

const InnerInput = React.forwardRef(function InnerInput({ label, name, ...props }, ref) {
	const id = React.useId();
	return (
		<React.Fragment>
			<StyledInput {...props} ref={ref} id={id} name={name} />
			<StyledLabel htmlFor={id}>{label}</StyledLabel>
		</React.Fragment>
	);
});

const DefaultLink = styled(Link)({
	textDecoration: "none",
	color: "inherit",
	"&:hover": {
		textDecoration: "underline"
	}
});

export default function Login() {
	const navigate = useNavigate();

	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = () => {
		setErrors([]);
	};

	useEffect(() => {
		const testConnection = async () => {
			try {
				const response = await axios.get("/api/auth/test/");
				console.log(response);
			} catch (error) {
				setErrors(["Connection error. Please try again later."]);
			}
		};

		testConnection();
	}, []);

	return (
		<main className="login">
			<Stack className="container">
				<Stack className="left-container">
					<DefaultLink href="/" className="go-back-link">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="icon icon-tabler icon-tabler-x"
							width="16"
							height="16"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="#1c1528"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M18 6l-12 12" />
							<path d="M6 6l12 12" />
						</svg>
						<span className="go-back">Close</span>
					</DefaultLink>
					<Stack className="form-container" spacing={1.5}>
						<h2>Welcome back!</h2>
						<form
							autoComplete="off"
							onSubmit={async event => {
								event.preventDefault();
								setErrors([]);
								setIsLoading(true);
								const formData = new FormData(event.currentTarget);
								const formJson = Object.fromEntries(formData.entries());
								console.log(formData.entries());
								console.log("formJson ->", formJson);

								try {
									const response = await axios.post("/api/auth/login/", JSON.stringify(formJson), {
										headers: {
											"Content-Type": "application/json"
										}
									});
									setTimeout(async () => {
										setIsLoading(false);
										console.log(response);
										setToken("access", response.data.access);
										navigate("/dashboard/projects/");
									}, 50);
								} catch (error) {
									console.log(error);
									const newErrors = [];

									if (typeof error.response.data === "object") {
										for (let err in error.response.data) {
											if (Array.isArray(error.response.data[err])) {
												console.error(error.response.data[err][0] + error);
												newErrors.push(error.response.data[err][0]);
											} else {
												console.error(error.response.data[err] + error);
												newErrors.push(error.response.data[err]);
											}
										}
									} else if (typeof error.response.data === "string") {
										console.error(error.response.data + error);
										newErrors.push(error.response.data);
									}

									setIsLoading(false);
									setErrors(newErrors);
								}
							}}
						>
							<Stack spacing={1}>
								<Input
									// * USERNAME INPUT
									variant="soft"
									slots={{ input: InnerInput }}
									slotProps={{
										input: { placeholder: "", type: "text", label: "Username", name: "username" }
									}}
									sx={{
										"--Input-focusedHighlight": "rgba(13,110,253,.25)",
										"&::before": {
											transition: "box-shadow .15s ease-in-out"
										},
										"&:focus-within": {
											borderColor: "#86b7fe"
										}
									}}
									onChange={handleChange}
									required
								/>
								<Input
									// * PASSWORD INPUT
									variant="soft"
									placeholder="Enter password"
									slots={{ input: InnerInput }}
									slotProps={{
										input: {
											placeholder: "",
											type: "password",
											label: "Password",
											name: "password"
										}
									}}
									sx={{
										"--Input-focusedHighlight": "rgba(13,110,253,.25)",
										"&::before": {
											transition: "box-shadow .15s ease-in-out"
										},
										"&:focus-within": {
											borderColor: "#86b7fe"
										}
									}}
									required
									onChange={handleChange}
								/>
								{/* Display Errors */}
								<Stack spacing={0.5}>
									{errors.map((error, index) => (
										<span
											key={index}
											style={{ color: "var(--joy-palette-danger-500)", fontSize: "14px" }}
										>
											<lord-icon
												src="https://cdn.lordicon.com/lfqzieho.json"
												trigger="loop"
												delay="1000"
												colors="primary:#c41c1c"
												style={{ width: "19px", minWidth: "19px", height: "19px" }}
											></lord-icon>
											{error}
										</span>
									))}
								</Stack>
								<Button
									type="submit"
									loading={isLoading}
									sx={{
										padding: "calc(0.8em + 4px) 0",
										fontSize: "inherit"
									}}
								>
									Continue
								</Button>
							</Stack>
						</form>
						<p>
							Don't have an account yet? <Link href="/create-account">Create an account</Link>
						</p>
					</Stack>
				</Stack>
				<Stack className="img-container">
					{/* <Player autoplay loop src={animationData} style={{ height: "500px", width: "500px" }}> */}
					<Player
						autoplay
						loop
						src="https://lottie.host/90f53999-ab60-4a5e-ab48-49d49418c95a/XNOJjUJQRy.json"
						style={{ height: "600px", width: "600px" }}
					>
						<Controls visible={false} buttons={["play", "repeat", "frame", "debug"]} />
					</Player>
				</Stack>
			</Stack>
		</main>
	);
}
