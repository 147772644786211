export const setToken = (tokenName, token) => {
	localStorage.setItem(tokenName, token);
};

export const getToken = tokenName => {
	if (tokenIsValid(tokenName)) {
		return localStorage.getItem(tokenName);
	} else {
		localStorage.removeItem(tokenName);
		return null;
	}
};

export const tokenIsValid = tokenName => {
	const token = localStorage.getItem(tokenName);

	if (!token) return false;

	const exp = JSON.parse(atob(token.split(".")[1])).exp;
	const now = Date.now() / 1000;

	if (exp > now) {
		return true;
	}
};
