import OpenAI from "openai";

import { Button, CircularProgress } from "@mui/joy";
import { useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export default function AiSuggestionButton({ description, name, addAiSuggestion, featuresItems }) {
	const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });
	const [isLoading, setIsLoading] = useState(false);

	const generateSuggestion = async () => {
		setIsLoading(true);

		try {
			const response = await openai.chat.completions.create({
				model: "gpt-3.5-turbo",
				messages: [
					{
						role: "user",
						content: `I am working on a new project. The name of the project is ${name}. The description of the project is ${description}. I want you to provide one feature the project could have with no extra words or discussion. You must simply respond with one feature that is less than ten words. This format is very important. If you do not follow this format, I will not be able to understand your suggestions. Examples of features include landing page carousel, live 3d car interior editor, recipe search bar, search with filters. The project's current features are: ${featuresItems}. You must not suggest a feature that is already included or anything that can be deemed equal. Here is one feature the project could have:`
					}
				]
				// temperature: 0.3
			});
			console.log(featuresItems);
			console.log(response.choices[0].message);

			const formattedResponse = response.choices[0].message.content.replace(".", "").trim();

			addAiSuggestion(formattedResponse);

			setIsLoading(false);
		} catch (error) {
			console.error("Error:", error);
			setIsLoading(false);
		}
	};

	return (
		<Button
			size="sm"
			color="primary"
			variant="solid"
			onClick={() => generateSuggestion()}
			loading={isLoading}
			loadingIndicator={<CircularProgress sx={{ transform: "scale(0.65)" }} />}
			disabled={isLoading || !description}
			startDecorator={<AutoAwesomeIcon sx={{ fontSize: "13px" }} />}
			sx={{
				"&:hover": {
					backgroundColor: "var(--joy-palette-primary-200)",
					transform: "scale(1.03)",
					transition: "transform 0.2s ease-out"
				},
				fontSize: "0.65rem",
				minHeight: 0,
				padding: "0.5rem"
			}}
		>
			Suggest Feature
		</Button>
	);
}
