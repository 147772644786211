import Input from "@mui/joy/Input";

export default function SearchBar({ searchValue, onSearchChange, placeholder }) {
	return (
		<Input
			value={searchValue}
			onChange={e => onSearchChange(e.target.value)}
			placeholder={placeholder}
			startDecorator={
				<svg
					className="feather feather-search"
					fill="none"
					width="15"
					height="15"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					viewBox="0 0 24 24"
				>
					<circle cx="11" cy="11" r="8" />
					<line x1="21" x2="16.65" y1="21" y2="16.65" />
				</svg>
			}
			sx={{ width: "100%" }}
		/>
	);
}
